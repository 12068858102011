import { createAction, props } from '@ngrx/store';
import { IUser, LoginData, LoginResponse } from '../../core/models/auth.model';

export const login = createAction(
  '[Auth] Login',
  props<{ loginData: LoginData }>()
);

export const loginSuccess = createAction(
  '[Auth] Login Succes',
  props<{ loginSuccessResponse: LoginResponse }>()
);

export const loginFailure = createAction(
  '[Auth] Login Failure',
  props<{ error: string }>()
);

export const autoLogin = createAction(
  '[Auth] Autologin',
  props<{ user: IUser }>()
);

export const logout = createAction('[Auth] Logout');

export const logoutSuccess = createAction('[Auth] Logout Succes');

export const logoutFailure = createAction('[Auth] Logout Failure');

export const clearError = createAction('[Auth] Clear Error');

//akcja do pobrania z localstorage i zapisania state
